<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            Push Notifications
            <v-spacer></v-spacer>
            <export-excel
                :data="pushNotificationExport"
                name="push_notifications.xls"
            >
              <v-btn small>Export to Excel</v-btn>
            </export-excel>
          </v-card-title>
          <v-data-table
              :headers="headers"
              :items="all_push_notifications"
              :search="search"
          >
            <template v-slot:top>
              <v-container fluid>
                <v-row>
                  <v-col>
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-select
                        :items="[{text: 'All', value: null}, {text: 'Delivered', value: true}, {text: 'Not Delivered', value: false}]"
                        placeholder="Filter By Delivery Status" v-model="deliveredSearch">
                    </v-select>
                  </v-col>
                  <v-col>
                    <v-menu
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="pushNotificationsSentRangeText"
                            placeholder="Sent Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            clearable
                        ></v-text-field>
                      </template>
                      <v-date-picker
                          v-model="sentDateSearch"
                          range
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-container>
            </template>
            <template v-slot:item.delivered="{ item }">
              <span
                  :class="{'green--text': item.delivered, 'red--text': !item.delivered}">{{
                  item.delivered ? 'Yes' : 'No'
                }}</span>
            </template>
            <template v-slot:item.sent_date="{ item }">
              {{ formatDateTime(item.sent_date) }}
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapState} from "vuex";
import dateUtils from '../mixins/date'

export default {
  name: "push_notifications",
  mixins: [dateUtils],
  data: () => ({
    all_push_notifications: [],
    search: '',
    sentDateSearch: null,
    deliveredSearch: null
  }),
  computed: {
    ...mapState(['push_notifications']),
    headers() {
      return [
        {text: 'User', value: 'user.username'},
        {text: 'Title', value: 'title', width: '30%', filterable: false},
        {text: 'Message', value: 'message', width: '30%', filterable: false},
        {text: 'Sent Date', value: 'sent_date', filter: this.filterSentDate},
        {text: 'Delivered', value: 'delivered', filter: this.filterDelivered},
      ]
    },
    pushNotificationsSentRangeText: {
      get() {
        if (!this.sentDateSearch) {
          return ''
        }
        const dates = []
        this.sentDateSearch.forEach(date => {
          dates.push(this.formatDate(date))
        })
        return dates.join(' - ')
      },
      set() {
        this.sentDateSearch = null
      }
    },
    pushNotificationExport() {
      const pushNotificationExport = []
      this.all_push_notifications.forEach(push_notifications => {
        pushNotificationExport.push({
          'Phone': push_notifications.phone,
          'Message': push_notifications.message,
          'Sent Date': this.formatDateTime(push_notifications.sent_date),
          'Delivered': push_notifications.delivered ? 'Yes' : 'No',
        })
      })

      return pushNotificationExport
    }
  },
  mounted() {
    this.fetchPushNotifications()
  },
  methods: {
    fetchPushNotifications() {
      this.$axios.get('/push-notifications').then(resp => {
        this.all_push_notifications = resp.data
      })
    },
    filterSentDate(value) {
      if (!this.sentDateSearch) {
        return true;
      }

      return this.isDateInRange(value, this.sentDateSearch[0], this.sentDateSearch[1])
    },
    filterDelivered(value) {
      if (this.deliveredSearch === null) {
        return true;
      }

      return value === this.deliveredSearch
    }
  }
}
</script>

<style scoped>

</style>